import React from 'react';
import './App.css';
import Advices from './components/Advices';

function App() {
  return (
    <div className="App">
     <h1>Random Advice</h1>
     <Advices/>
    </div>
  );
}

export default App;
